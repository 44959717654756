body {
  background-color: rgb(28, 30, 43);
  font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6, p, span, a {
  font-family: 'Poppins', sans-serif;
}

/* =============================Dashboard Page================================================================================================================================================================================================================== */
.dashboard-left-side-div-wrapper-Main {
  padding: 30px;
}

.dashboard-left-side-div-one-wrapper {
  background-color: #282F42;
  padding: 16px;
  border-radius: 5px;
  position: relative;
}

.dashboard-left-side-div-one-wrapper h3 {
  color: white;
  font-size: 17px;
  margin-bottom: 0px;
  text-align: start;
}

.dashboard-left-side-div-one-inner-div {
  text-align: end;
  margin-top: -8px;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: end;
  padding-left: 11px;
  padding-right: 0px;
  margin-top: 10px;
}

.dashboard-left-side-div-one-inner-div-two {
  text-align: end;
  /* margin-top: -8px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: end;
  /* padding-left: 11px;
  padding-right: 0px; */
  /* margin-top: 10px; */
}

.dashboard-left-side-div-one-inner-div svg {
  margin-top: -10px;
}

.dashboard_page_battery_icon_wrapper {
  display: flex;
  align-items: flex-start;
}

.dashboard-left-side-div-one-wrapper span {
  color: rgb(113, 207, 98);
  font-size: 13px;
}

.dashboard-left-side-div-one-wrapper-battery-div p {
  margin-bottom: 0px;
  color: white;
  font-size: 12px;
  font-weight: 800;
  margin: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.dashboard-left-side-div-one-wrapper-battery-div {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: end;
}

.dashboard-left-side-div-two-wrapper-h5 {
  color: white;
  font-weight: 300;
  font-size: 15px;
}

.dashboard-left-side-div-three-wrapper {
  margin-top: 16px;
}

.dashboard-left-side-div-four-wrapper {
  margin-top: 16px;
}

.dashboard_page_Order_Id_Wrapper {
  display: flex;
  justify-content: space-between;
  /* margin-bottom:5px; */
  margin-right: 22px;
  width: 100%;
}

.dashboard_page_Order_Id_Wrapper_inner_div {
  display: flex;
}

.dashboard_page_Order_Id_Wrapper_inner_div h3 {
  color: white;
  font-size: 18px;
  margin-bottom: 0px;
  align-self: center;
}

.dashboard-left-side-div-two-Map-Container-one {
  background-color: #282F42;
  height: 170px;
  overflow: scroll;
}

.dashboard-left-side-div-two-Map-Container-two {
  background-color: #282F42;
  /* height: 170px; */
  /* border:3px solid rgb(251,98,98) !important; */
  border-radius: 5px !important;
}

.dashboard-left-side-div-two-Map-Container-two h4 {
  color: white;
  font-size: 17px;
  text-align: center;
  padding: 19px 13px;
}

.connectionstatus-red {
  background-color: rgb(220, 53, 69);
  width: 13px;
  height: 13px;
  border-radius: 100%;
  display: inline-block;
  margin-bottom: 7px;
  margin-left: 3px;

}

.connectionstatus-green {
  background-color: rgb(113, 207, 98);
  width: 13px;
  height: 13px;
  border-radius: 100%;
  display: inline-block;
  margin-bottom: 7px;
  margin-left: 3px;

}

.dashboard_left_side_available_div {
  text-align: left;
}

.dashboard_left_side_available_div h4 {
  font-size: 10px;
  color: white !important;
}

.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar {
  background-color: #282F42;
  color: #fff !important;
  width: 10px;
}

.col-md-9.dashboard-right-side-div-wrapper-Main h2 {
  font-size: 22px;
  color: white;
  padding: 50px;
  padding-bottom: 0px;
  font-size: 17px;
  padding-left: 30px;
}

.leaflet-marker-icon {
  position: relative;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border-radius: 100%;
}

@keyframes example {
  0% {
    transition: 1s ease-in-out;
    /* box-shadow: 0px 0px 0px 3px #fff; */
  }

  50% {
    transition: 1s ease-in-out;
    /* box-shadow: 0px 0px 0px 3px #fff; */
  }

  100% {
    border: none;
    transition: 1s ease-in-out
  }
}

@keyframes blink {
  0% {
    box-shadow: 0;
  }

  50% {
    box-shadow: .5;
  }

  100% {
    box-shadow: 1;
  }
}

.Dashboard_page_Map_wrapper_Main_whole img {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}

.Dashboard_page_Map_wrapper_Main_whole {
  padding: 2rem;
  padding-top: 1rem;
  padding-right: 0px;
  padding-left: 0px;
}

.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar-track {
  color: #ffffff !important;
}

.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar-thumb {
  background-color: #7d7d7d !important;

}

.dashboard-left-side-div-two-robot-button-Container.d-flex-center {
  background-color: #282F42;
  border-radius: 5px;
  flex-wrap: wrap;
}

.dashboard-left-side-div-two-robot-button-Container button {
  border-radius: 5px;
  padding: 5px 24px;
  background-color: rgb(189, 196, 224);
  border: none !important;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 17px;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid transparent;
}

.dashboard-right-side-div-wrapper-Main {
  background-color: #282F42;
  height: auto;
}

.Dashboard-Page-bottom-div-Main-wrapper {
  width: 100%;
  background-color: black;
  position: relative;
  top: 0;
  padding: 10px 25px;
  /* z-index:999999 !important; */
}

.Dashboard_page_top_div_inner_div {
  margin-left: 20px;
  margin-right: 20px;
}

.Dashboard_page_bottom_div_inner_one_div {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-direction: column;
  /* width: 25%; */
}

.Dashboard_page_bottom_div_inner_two_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 85%;
}

.Dashboard_page_bottom_div_inner_two_div button {
  margin-right: 10px;
  margin-left: 10px;
}

.Dashboard_page_bottom_div_inner_two_div_inner_one {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Dashboard_page_bottom_div_inner_two_div_inner_two {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.DashBoard-page-Main-Whole-wrapper {
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow: hidden;
}

.Dashboard_page_2d_map_button {
  position: absolute;
  bottom: 70px;
  left: 60px;
  background-color: #BDC4E0;
  border: 5px solid white;
  border-radius: 100%;
  padding: 17px;
}

.Dashboard-Page-bottom-div-Main-wrapper-content-p {
  color: white !important;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-left: -6px;
}

.direction_toggle_p {
  color: white !important;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 0px !important;
  margin-left: -6px;
}

.Dashboard-Page-bottom-div-Main-wrapper-content-span {
  color: rgb(189, 196, 224);
}

.Dashboard-Page-bottom-div-Main-wrapper.d-flex-center button {
  background-color: #282F42;
  border: 2px solid #a2a2a2;
  border-radius: 5px;
  padding: 4px 22px;
  font-size: 12px;
  color: white;
  font-weight: 200;
}

.Dashboard-page-teleop-button-disabled {
  background-color: #78797a !important;
}

.Dashboard-Page-Power-button {
  background-color: #282F42;
  border-radius: 30px;
  width: 35px !important;
  height: 35px !important;
  padding: 5px;
}

.col-md-9.dashboard-right-side-div-wrapper-Main {
  height: 90vh;
  position: relative;
}

.Stop-button-dashhboard-page {
  background-color: rgb(228 51 41) !important;
  color: white !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  border: none !important;
  padding: 6px 33px !important;
}

button#long-button {
  border: none;
  background-color: transparent;
  padding: 4px 0px;
}

.Dashboard_page_sidebar_button {
  background-color: transparent;
  color: white;
  border: none;
  font-family: "Poppins", sans-serif;
}

.play-icon {
  cursor: pointer;
}

#long-menu ul {
  padding: 0px !important;
}

.Dashboard-page-teleop-button {
  /* border:2px solid rgb(220,53,69) !important;  */
}

.Dashboard_page_sidebar_menuItem {
  padding: 10px !important;
  border-bottom: 1px solid #eaeaea !important;
  /* justify-content: end !important; */
}

.Dashboard_page_sidebar_menuItem_last {
  padding: 10px !important;

}

.Error-state-teleop-ack-button {
  background-color: rgb(220, 53, 69) !important;
  color: white !important;
  font-size: 10px;
  position: absolute;
  padding: 1px 6px 2px 6px;
  right: 1rem;
  top: -10px;
  border-radius: 5px;
  z-index: 1;
  border: none !important;
}

.dashboard_page_Map_heading_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 12px;
}

.dashboard-left-side-div-two-robot-button-Container button.Dashboardpage_CloseDoor_button {
  border: 2px solid rgb(189, 196, 224) !important;
  color: rgb(189, 196, 224);
  background: none !important;
}

.Teleop_completed_btn {
  border: 2px solid rgb(189, 196, 224) !important;
  color: #282F42 !important;
  background: rgb(189, 196, 224) !important;
  font-weight: bold !important;
}

.Dashboard_page_OpenDoor_deactivate {}

.dashboard-left-side-div-one-wrapper .Dashboard-page-teleop-button {
  border: 2px solid #e43329;
  background: #bdc4e0;
  border-radius: 7px;
  display: block;
  margin: 0 auto 10px;
  padding: 7px 20px;
  max-width: 150px;
  font-weight: bold;
  text-align: center;
}

.dashboard-left-side-div-one-wrapper .Dashboard-page-teleop-button.Teleop_completed_btn {
  border: 2px solid #e43329 !important;
  background: #FFF !important;
  border-radius: 7px;
  display: block;
  margin: 0 auto 10px;
  padding: 7px 20px;
  max-width: 200px;
  font-weight: bold;
  font-size: 16px !important;
  text-align: center;
}

.direction-toggle {
  display: flex;
  align-items: center;
}

.Map-Main-Whole-wrapper.border-blue-top {
  position: absolute;
  bottom: 146px;
  left: 69px;
  background-color: white;
  z-index: 999999999999 !important;
  border: 7px solid black;
  width: 347px;
  height: 270px;
}

/* =============================End================================================================================================================================================================================================================== */

/* ============================LoginPage========================================================================================================================================================================================================================== */

.Login-page-form {
  width: 35%;
  margin: auto;
  background: rgb(40, 47, 66);
  /* box-shadow:0px 4px 33px 12px rgb(40,47,66); */
  padding: 25px;
  padding-top: 37px;
  border-radius: 8px;
  text-align: center;
}

input:focus {
  background-color: transparent !important;
}

/* input:-webkit-autofill{
  background-color: transparent !important;
  color:transparent !important;
} */
.Login-page-form input:-webkit-autofill,
.Login-page-form input:-webkit-autofill:hover,
.Login-page-form input:-webkit-autofill:focus,
.Login-page-form input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(40, 47, 66) inset !important;
  -webkit-text-fill-color: rgb(189, 196, 224) !important;
}

.LoginPage-Main-wrapper {
  height: 100vh;
  align-items: center;
  align-self: center;
  vertical-align: middle;
  /* background-color: white; */
  display: flex;
  width: 100%;
}

.Login-page-form .css-1a1fmpi-MuiInputBase-root-MuiInput-root {
  width: 95%;
  margin: auto;
}

.Login-page-form-logo-image {
  width: 25%;
  display: block;
  margin: 0 auto;
}

img.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive:last-of-type {
  margin-left: -22px !important;
  margin-top: -30px !important;
}

.Loginpage-main-form-inner-div {
  margin-top: 44px !important;
}

.Login-form-p {
  margin: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: rgb(1, 115, 212);
  font-weight: 600;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}

.Login-page-form h6 {
  margin: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-size: 17px;
  margin-top: 0px !important;
}

.Loginpage\=-main-form {
  /* margin: 24px; */
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 48px;
  margin-top: 24px;
}

.Login-page-form input {
  font-size: 19px !important;
  font-weight: 600 !important;
  color: rgb(189, 196, 224) !important;
  letter-spacing: 0px !important;
}

.Login-page-form .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 15.9px;
  font-family: 'Poppins', sans-serif;
  color: rgb(189, 196, 224);
  font-weight: 500;
}

.Loginpage-btn-wrapper-btn {
  color: black !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 20px !important;
  background-color: rgb(189, 196, 224) !important;
  padding: 10px !important;
  border-radius: 7px !important;
  width: 100% !important;
  border: none;
  font-weight: 700;
}

.Login-page-form .css-1d1r5q-MuiFormHelperText-root.Mui-error {
  font-weight: 400;
  font-size: 10px;
  padding-left: 39px;
}

.Login-page-form .css-dmmspl-MuiFormGroup-root {
  padding-left: 15px
}

img.Logout-image {
  width: 100%;
  margin-left: 2px;
}

.Login-page-remember-checkbox {
  width: 25px;
  height: 25px;
}

.Login-page-remember-checkbox-span {
  margin: 10px;
  color: rgb(189, 196, 224);
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input:-webkit-autofill {
  background-color: transparent !important;
}

/* =============================End================================================================================================================================================================================================================== */

/* ==================SelectrFleetPage=======================================================================================================================================================   */
.SelectFleetPage-content-wrapper-Main {
  display: flex;
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  gap: 20px;
}

.SelectFleetPage-inner-li-div {
  width: 100%;
  /* margin: 7px;
  padding: 15px 10px 15px 15px; */
  cursor: pointer;
  border-top: none !important;
  border-radius: 8px;
  position: relative !important;
  background: #1C1E2A;
}

.SelectFleetPage-content-wrapper-Main.border-red {
  justify-content: flex-start;
  width: 407px;
  margin: 5px;
  background-color: rgb(40, 47, 66);
}

.Select-page-fleetName {
  color: #b7b7b7 !important;
  font-size: 16px;
}

.SelectFleetPage-content-wrapper-Main .Dashboard-content-div-one-left-side-h3 {
  text-align: start !important;
  /* border-bottom: 3px solid #efefef; */
  padding: 6px;
}

.SelectFleetPage-content-wrapper-Main .d-flexx-center {
  padding-right: 30px;
  padding-left: 30px;
}

.container.SelectFleetPage-wrapper-Main {
  margin-top: 3rem;
}

.container.SelectFleetPage-wrapper-Main .SelectPageMain-Heading {
  margin: 22px;
  margin-left: 0px;
}

.SelectFleet-Page-p-tag {
  font-size: 21px;
}

.SelectFleet-Page-h5-tag {
  font-weight: 800;
}

.SelectFleetPage-inner-li-div-robot-id {
  margin-bottom: 0px;
}

.SelectPage-robotList-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: start;
  /* background: #292F40; */
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.Select-Page-Robot-Message-Heading {
  color: #ffffff !important;
  text-transform: uppercase !important;
  font-size: 15px;
}

.SelectFleet-page-Error-box-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

/* ==================End=======================================================================================================================================================   */
/* =====================Header===================================================================================================================================================================== */
.Header-Add-new-order-btn {
  border-radius: 30px;
}

.Header-Main-wrapper {
  /* box-shadow: 0px 16px 14px 5px lightgrey; */
  box-shadow: 0 0 80px rgba(0, 0, 0, 0.1);
  padding: 14px 65px;
}

.HeaderMain-logo-image {
  width: auto;
  height: 34px;
  min-width: 221px;
}

/* .HeaderMain-logo-image {
  width: 7%;
} */

button.Header-Add-new-order-btn {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 6px 20px !important;
  background-color: rgb(1, 115, 212) !important;
  color: #ffffff !important;
  border-radius: 20px !important;
}

.container-fluid.SelectFleetPage-wrapper-Main {
  /* padding-left: 30px;
  padding-right: 30px; */
  /* margin-top: 2rem; */
  margin-bottom: 2rem;
}

.row.justify-content-between {
  justify-content: end;
}

/* =========================End================================================================================================================================================================================= */
/* =============================Disengage Modal======================================================================================================================================================================================== */
.Modal-wrapper-p {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 1.6000000238px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

/* ==================================End====================================================================================================================================================================================================================== */
.No-robots-available {
  padding: 10px 20px;
  font-size: 14px;
  color: #bcc4df;
}

.RobotActions-content-wrapper-div {
  color: #ffffff !important;
  padding: 19px;
  border-radius: 5px;
  text-align: center;
}

.RobotActions-content-wrapper-div h3 {
  font-size: 15px;
  margin-bottom: 10px;
}

.RobotActions-content-wrapper-div p {
  font-size: 13px;
  margin-bottom: 0px;
}

.RobotActions-button-wrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.RobotActions-button-wrapper button {
  border: none;
  color: white !important;
  border-radius: 5px;
  font-size: 13px;
  padding: 5px 15px;
  margin: 5px;
}

.RobotActions-Start-btn {
  background-color: rgb(40, 167, 69);
}

.RobotActions-Start-btn-disabled {
  background-color: rgb(117, 189, 134);
  color: rgb(223, 219, 219) !important;
}

.RobotActions-cancel-btn {
  background-color: rgb(220, 53, 69);
}

/* =============================Responsive================================================================================================================================================================================================================== */
/* =============================End================================================================================================================================================================================================================== */
#map {
  height: 100vh;
}

.leaflet-popup-content-wrapper {
  width: 100%;
}

@keyframes example {
  0% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 3px #fff;
    border-radius: 100%;
  }

  50% {
    transition: 1s ease-in-out;
    box-shadow: 0px 0px 0px 3px #fff;
    border-radius: 100%;
  }

  100% {
    border: none;
    transition: 1s ease-in-out;
    border-radius: 100%;
  }
}

.gm-style div:nth-child(2) div:nth-child(2) div div:nth-child(3) div {
  position: relative;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border-radius: 100%;
}

/* .gm-style div:nth-child(2) div:nth-child(1) div:nth-child(2) div:nth-child(1) {
    position: absolute;
    left: 0px;
    top: -7px !important;
    z-index: 30;
} */
.battery-icon-css {
  width: 35px;
  position: relative;
}

.battery-icon-css:after {
  content: ' ';
  border-radius: 3px;
  width: 3px;
  height: 5px;
  background: #fff;
  position: absolute;
  top: 8px;
  right: 0px;
}

.Cancel-order-Modal-Mainwrapper p {
  margin-bottom: 20px;
  padding-left: 14px;
  color: white;
  text-align: center;
  padding: 0px;
}

.RobotActions_popup_content_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(189, 196, 224);
  margin-top: 26px;
  margin-bottom: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.RobotActions_popup_content_wrapper p {
  font-size: 16px !important;
  color: #282F42;
}

.RobotActions_popup_content_wrapper h4 {
  color: #282F42;
  color: #282F42;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
}

.Cancel-order-Modal-Mainwrapper {
  padding: 32px;
  padding-right: 0px;
  padding-left: 0px
}

.Cancel-order-Modal-Mainwrapper h5 {
  color: rgb(40, 167, 69);
  text-align: center;
  font-weight: 600;
  font-size: 24px;
}

.Cancel-order-Modal-Mainwrapper p {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight: 600;
}

.Modal-button-Main-btn {
  border-radius: 7px !important;
  border: 1px solid white !important;
  color: white !important;
  padding: 8px 55px !important;
  font-weight: 600 !important;
  margin: 10px !important;
  margin-top: 0px !important;
}

.yes-red-active {
  background: white !important;
  color: black !important;
}

.Modal-button-Main-wrapper {
  margin-top: 22px;
  text-align: center;
}

.dashboard-left-side-div-one-wrapper_ERROR {
  border: 5px solid #fb6262;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

.dashboard-left-side-div-one-wrapper.state_Teleop_Moving {
  border: 5px solid #7859bc;
}

.robotRunningState_teleopmoving {
  background-color: #7859bc;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  border: 2px solid #7859bc;
}

.teleopMoving_bordertop {
  border-top: 8px solid #7859bc;
}

.dashboard-left-side-div-one-wrapper.state_Auto_run_moving {
  border: 5px solid #40B4D0;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

.robotRunningState_autorunmoving {
  background-color: #40B4D0;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  border: 2px solid #40B4D0;
}

.AutoRun_moving {
  border-top: 8px solid #40B4D0
}

.dashboard-left-side-div-one-wrapper.state_Auto_Obstacle-Teleop_Obstacle {
  border: 5px solid #e6883c;
  padding: 25px 10px;
  border-radius: 7px;
  background-color: black;
}

.robotRunningState_state_Auto_Obstacle-Teleop_Obstacle {
  background-color: #e6883c;
  border-radius: 10px;
  padding: 10px 25px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  border: 2px solid #e6883c;
}

.AutoObstacle_teleop_obstacle_bordertop {
  border-top: 8px solid #e6883c
}

.dashboard-left-side-div-one-wrapper.state_Auto_system_fault_error {
  border: 5px solid rgb(228 51 41);
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

.robotRunningState_autoSystemFault {
  background-color: rgb(228 51 41);
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  border: 2px solid rgb(228 51 41);
}

.AutoSystem_fault_error_bordertop {
  border-top: 8px solid rgb(228 51 41);
}

.dashboard-left-side-div-one-wrapper.state_payload_state {
  border: 5px solid #f0b156;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

.robotRunningState_payloadState {
  background-color: #f0b156;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  border: 2px solid #f0b156;
}

.orange_border {
  border: 4px solid #f0b156 !important;
}

.payloadState_bordertop {
  border-top: 8px solid #f0b156;
}

.dashboard-left-side-div-one-wrapper.state_Teleop_Autorun {
  border: 5px solid rgb(113, 207, 98);
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

.robotRunningState_teleop_Autorun, .robotRunningState_state_Teleop_Autorun {
  background-color: rgb(113, 207, 98);
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  border: 2px solid rgb(113, 207, 98);
}

.Teleop_autoRun_borderTop {
  border-top: 8px solid rgb(113, 207, 98);
}

.robotRunningState_default {
  background-color: #282F42;
  border: 2px solid #a2a2a2;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: white;
  font-weight: bold;
}

.dashboard-left-side-div-one-wrapper {
  border: 2px solid #a2a2a2;
}

.defaultBorderTop {
  border-top: 8px solid #a2a2a2;
}

.RobotRunning_state_Button_wrapper {
  text-align: center;
  margin: 18px;
  color: white !important;
}

.tools {
  text-align: end;
  position: absolute;
  z-index: 999999;
  right: 20px;
  display: flex;
}

.tools button {
  background: #3D4665 !important;
  font-weight: 600;
  margin: 5px;
  color: #BCC4DF;
  border: none;
  padding: 2px 12px;
  border-radius: 5px;
}

.Dashboard-content-div-one-left-side-h3 {
  color: #00B7D4 !important;
  text-align: left !important;
  font-weight: 700;
  font-size: 20px;
  /* padding: 15px 25px; */
  margin-bottom: 0px;
}

button.Dashboard_page_2d_map_button {
  display: none !important;
}

.reset_diagonostics_button {
  background: transparent;
  color: white;
  font-family: "Poppins", sans-serif;
  border: none;
}

.Estop_reset_diagnostics_wrapper {
  display: flex;
}


/* ============================Responsive=======================================================================================================================================================================================================================================*/
@media only screen and (max-width: 700px) {
  .Dashboard_page_bottom_div_inner_two_div_inner_one {
    display: none !important;
  }

  .Estop_reset_diagnostics_wrapper {
    display: none !important;
  }

  .Dashboard_page_bottom_div_inner_two_div {
    justify-content: end;
    width: 25%;
  }

  .Dashboard_page_bottom_div_inner_one_div {
    width: 100%;
  }

  .Dashboard_page_whole_wrapper {
    flex-direction: column-reverse !important;
  }

  .col-md-9.dashboard-right-side-div-wrapper-Main {
    height: 62vh;
  }

  .col-md-9.dashboard-right-side-div-wrapper-Main>div>div {
    height: 450px !important;
  }

  .Online_offline_div {
    align-self: unset !important;
  }

  .Login-page-form {
    width: 90%;
  }

  .HeaderMain-logo-image {
    width: 30%;
  }

  .col-md-3.dashboard-left-side-div-wrapper-Main {
    margin-top: 45px;
  }
}

/* ============================End=======================================================================================================================================================================================================================================*/

.Dashboard_page_individual_robot_robotstatus_div {
  text-align: center;
  color: white !important;
  font-size: 12px;
}

.dashboard-left-side-div-one-wrapper_ERROR {
  border: 5px solid #fb6262;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: var(--black);
}

.dashboard-left-side-div-one-wrapper.state_Teleop_Moving {
  border: 5px solid #7859bc;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: var(--black);
}

.No_video_available_div {
  background-color: var(--drakgrey);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.No_video_available_div p {
  color: var(--white);
  font-size: 17px;
}

.robotRunningState_teleopmoving {
  background-color: #7859bc;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: var(--white);
  font-weight: bold;
  border: 2px solid #7859bc;
}

.teleopMoving_bordertop {
  border-top: 8px solid #7859bc;
}

.teleopMoving_bordertop_p {
  color: var(--white);
}

.teleopMoving_bordertop_p span.state_border::after {
  background: #7859bc;
}

.dashboard-left-side-div-one-wrapper.state_Auto_run_moving {
  border: 5px solid #40B4D0;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: var(--black);
}

.robotRunningState_autorunmoving {
  background-color: #40B4D0;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: var(--white);
  font-weight: bold;
  border: 2px solid #40B4D0;
}

.AutoRun_moving {
  border-top: 8px solid #40B4D0
}

.AutoRun_moving_p span.state_border::after {
  background: #40B4D0;
}

.AutoRun_moving_p {
  color: var(--white);
}

.dashboard-left-side-div-one-wrapper.state_Auto_Obstacle-Teleop_Obstacle {
  border: 5px solid #e6883c;
  padding: 25px 10px;
  border-radius: 7px;
  background-color: var(--black);
}

.robotRunningState_state_Auto_Obstacle-Teleop_Obstacle {
  background-color: #e6883c;
  border-radius: 10px;
  padding: 10px 25px;
  font-size: 15px;
  color: var(--white);
  font-weight: bold;
  border: 2px solid #e6883c;
}

.AutoObstacle_teleop_obstacle_bordertop {
  border-top: 8px solid #e6883c;
}

.AutoObstacle_teleop_obstacle_p {
  color: var(--white);
}

.AutoObstacle_teleop_obstacle_p span.state_border::after {
  background: #e6883c;
}

.dashboard-left-side-div-one-wrapper.state_Auto_system_fault_error {
  border: 5px solid rgb(228 51 41);
  padding: 10px 10px;
  border-radius: 7px;
  background-color: var(--black);
}

.robotRunningState_autoSystemFault {
  background-color: rgb(228 51 41);
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: var(--white);
  font-weight: bold;
  border: 2px solid rgb(228 51 41);
}

.AutoSystem_fault_error_bordertop {
  border-top: 8px solid rgb(228 51 41);
}

.AutoSystem_fault_error_p {
  color: var(--white) !important;

}

span.state_border {
  display: inline-block;
  position: relative;
}

span.state_border::after {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
}

.AutoSystem_fault_error_p span.state_border::after {
  background: rgb(228 51 41);
}

.dashboard-left-side-div-one-wrapper.state_payload_state {
  border: 5px solid #f0b156;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: var(--black);
}

.robotRunningState_payloadState {
  background-color: #f0b156;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: var(--white);
  font-weight: bold;
  border: 2px solid #f0b156;
}

.payloadState_bordertop {
  border-top: 8px solid #f0b156;
}

.payloadState_bordertop_p {
  color: var(--white);
}

.payloadState_bordertop_p span.state_border::after {
  background: #f0b156;
}

.dashboard-left-side-div-one-wrapper.state_Teleop_Autorun {
  border: 5px solid rgb(113, 207, 98);
  padding: 10px 10px;
  border-radius: 7px;
  background-color: var(--black);
}

.robotRunningState_teleop_Autorun, .robotRunningState_state_Teleop_Autorun {
  background-color: rgb(113, 207, 98);
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: var(--white);
  font-weight: bold;
  border: 2px solid rgb(113, 207, 98);
}

.Teleop_autoRun_borderTop {
  border-top: 8px solid rgb(113, 207, 98);
}

.Teleop_autoRun_p {
  color: var(--white);
}

.Teleop_autoRun_p span.state_border::after {
  background: rgb(113, 207, 98);
}

.robotRunningState_default {
  background-color: var(--drakgrey);
  border: 2px solid #a2a2a2;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: var(--white);
  font-weight: bold;
}

.dashboard-left-side-div-one-wrapper {
  border: 2px solid #a2a2a2;
}

.defaultBorderTop {
  border-top: 8px solid #a2a2a2;
}

.defaultBorderTop_P {
  color: var(--white);
}

.defaultBorderTop_P span.state_border::after {
  background: #a2a2a2;
}

.RobotRunning_state_Button_wrapper {
  text-align: center;
  margin: 18px;
  word-break: break-word;
}

.red-dot {
  background-color: #FF6767;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  right: -10px;
}

.green-dot {
  background-color: #88F976;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  right: -10px;
  animation-name: dotanimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.d-flex-center.Dasboard_page_individual_robot_box_2 {
  position: relative !important;
}

.yellow-dot {
  background-color: #FFB800;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  right: -10px;
  animation-name: dotanimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes dotanimation {
  0% {
    opacity: 0
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

/* FLEET PAGE CSS */

.fleet__card {
  width: max-content;
  display: flex;
  flex-direction: column;
  background: #292F40;
  padding: 15px;
  border-radius: 8px;
  gap: 20px;
}

.fleet__card_name {
  color: #00B7D4 !important;
  text-align: left !important;
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 0px;
}

.fleet__card_details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  justify-content: center;
}

.fleet__card__robot_details {
  width: 330px;
  cursor: pointer;
  border-top: none !important;
  border-radius: 8px;
  position: relative !important;
  background: #1C1E2A;
}

.fleet__card__robot_details_container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.fleet__card__robot_details_robot_status_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px 10px 15px 15px;
}

.fleet__card__robot_details_robot_status {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  /* gap: 5px; */
}

.fleet__card__robot_details_robot_id {
  color: white;
}

.fleet__card__robot_details_robot_battery_container {
  text-align: end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.fleet__card__robot_details_robot_battery_details {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
}

.fleet__card__robot_details_robot_battery_details p {
  margin-bottom: 0px;
  color: white;
  font-size: 12px;
  font-weight: 800;
  margin: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.batter_percentage {
  width: 30px;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 2px;
  height: 14px;
  display: inline-block;
}

.battery_icon {
  width: 35px;
  position: relative;
}

.battery_icon:after {
  content: ' ';
  border-radius: 3px;
  width: 3px;
  height: 5px;
  background: #fff;
  position: absolute;
  top: 8px;
  right: 0px;
}

.Dashboard_page_individual_robot_robotstatus_div {
  text-align: center;
  color: white !important;
  font-size: 12px;
}

.dashboard-left-side-div-one-wrapper_ERROR {
  border: 5px solid #fb6262;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

.dashboard-left-side-div-one-wrapper.state_Teleop_Moving {
  border: 5px solid #7859bc;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

.No_video_available_div {
  background-color: #282F42;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.No_video_available_div p {
  color: white;
  font-size: 17px;
}

/* .teleopMoving_bordertop {
  border-top: 8px solid #7859bc;
} */

.teleopMoving_bordertop_p {
  color: white;
}

.teleopMoving_bordertop_p span.state_border::after {
  background: #7859bc;
}

.dashboard-left-side-div-one-wrapper.state_Auto_run_moving {
  border: 5px solid #40B4D0;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

/* .AutoRun_moving {
  border-top: 8px solid #40B4D0
} */

.AutoRun_moving_p span.state_border::after {
  background: #40B4D0;
}

.AutoRun_moving_p {
  color: white;
}

.dashboard-left-side-div-one-wrapper.state_Auto_Obstacle-Teleop_Obstacle {
  border: 5px solid #e6883c;
  padding: 25px 10px;
  border-radius: 7px;
  background-color: black;
}

/* .AutoObstacle_teleop_obstacle_bordertop {
  border-top: 8px solid #e6883c;
} */

.AutoObstacle_teleop_obstacle_p {
  color: white;
}

.AutoObstacle_teleop_obstacle_p span.state_border::after {
  background: #e6883c;
}

.dashboard-left-side-div-one-wrapper.state_Auto_system_fault_error {
  border: 5px solid rgb(228 51 41);
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

/* .AutoSystem_fault_error_bordertop {
  border-top: 8px solid rgb(228 51 41);
} */

.AutoSystem_fault_error_p {
  color: white !important;

}

span.state_border {
  display: inline-block;
  position: relative;
}

span.state_border::after {
  content: '';
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 2px;
}

.AutoSystem_fault_error_p span.state_border::after {
  background: rgb(228 51 41);
}

.dashboard-left-side-div-one-wrapper.state_payload_state {
  border: 5px solid #f0b156;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

/* .payloadState_bordertop {
  border-top: 8px solid #f0b156;
} */

.payloadState_bordertop_p {
  color: white;
}

.payloadState_bordertop_p span.state_border::after {
  background: #f0b156;
}

.dashboard-left-side-div-one-wrapper.state_Teleop_Autorun {
  border: 5px solid rgb(113, 207, 98);
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}

/* .Teleop_autoRun_borderTop {
  border-top: 8px solid rgb(113, 207, 98);
} */

.Teleop_autoRun_p {
  color: white;
}

.Teleop_autoRun_p span.state_border::after {
  background: rgb(113, 207, 98);
}

.dashboard-left-side-div-one-wrapper {
  border: 2px solid #a2a2a2;
}

/* .defaultBorderTop {
  border-top: 8px solid #a2a2a2;
} */

.defaultBorderTop_P {
  color: white;
}

.defaultBorderTop_P span.state_border::after {
  background: #a2a2a2;
}

.RobotRunning_state_Button_wrapper {
  text-align: center;
  margin: 18px;
  word-break: break-word;
}

.red-dot {
  background-color: #FF6767;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  right: -10px;
}

.green-dot {
  background-color: #88F976;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  right: -10px;
  animation-name: dotanimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.d-flex-center.Dasboard_page_individual_robot_box_2 {
  position: relative !important;
}

.yellow-dot {
  background-color: #FFB800;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  right: -10px;
  animation-name: dotanimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes dotanimation {
  0% {
    opacity: 0
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.battery__swap {
  width: 100%;
  height: 25px;
  background: #FF6767;
  border-radius: 7px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 15px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
}

.battery__percentage {
  width: 30px;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 2px;
  height: 14px;
  display: inline-block;
}

.fleet__details__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.fleet__details__container.border-red {
  justify-content: flex-start;
  width: 407px;
  margin: 5px;
  background-color: rgb(40, 47, 66);
}

.fleet__details__container .Dashboard-content-div-one-left-side-h3 {
  text-align: flex-start !important;
  /* border-bottom: 3px solid #efefef; */
  padding: 6px;
}

.fleet__details__container .d-flexx-center {
  padding-right: 30px;
  padding-left: 30px;
}

/* @media (min-width: 40rem) {
  .fleet__robot__details {
    width: 50%;
  }
}

@media (min-width: 56rem) {
  .fleet__robot__details {
    width: 32.333%;
  }
} */

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin: 10px 20px 0 0 !important;
}

.mapboxgl-ctrl-group {
  /* background-color: rgba(35, 55, 75, 0.9) !important; */
  /* color: white !important; */
}

/* // From: Pitch toggle control for Mapbox GL JS — http://fuzzytolerance.info/blog/2017/01/30/Pitch-toggle-control-for-Mapbox-GL-JS/ */
.mapboxgl-ctrl-pitchtoggle-3d {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjNEPC90ZXh0Pjwvc3ZnPg==) !important;
}

.mapboxgl-ctrl-pitchtoggle-2d {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjJEPC90ZXh0Pjwvc3ZnPg==) !important;
}